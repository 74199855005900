<template>
  <v-row class="campaign">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/campaigns" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>{{ data.name }}
          </router-link>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <div class="d-flex flex-wrap justify-space-between mb-sm-0 mb-3">
          <div class="text-uppercase text-h5 font-weight-bold">General</div>
          <div class="status white--text" :class="getColorClass(data.status.name)">
            {{ data.status.title }}
          </div>
        </div>
        <div class="px-sm-10 py-sm-5">
          <v-row>
            <v-col cols="12" md="6">
              <div class="font-weight-medium">Sender ID</div>
              <div class="opasity--text">{{ data.sender_id }}</div>
              <div class="font-weight-medium mt-3">Selected recipients type</div>
              <div class="opasity--text">{{ data.targets_type.title }}</div>
              <div v-if="data.targets_type.name == 'group'">
                <div class="font-weight-medium mt-3">Group</div>
                <router-link class="input--text" :to="`/campaigns/${data.campaign_id}/details`">{{ data.campaign_name }}</router-link>
              </div>
              <div v-else>
                <div class="font-weight-medium mt-3">Phone list</div>
                <div @click="modal = true" class="input--text link">Open List</div>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="font-weight-medium">Sending mode</div>
              <div class="opasity--text">{{ data.send_option.title }}</div>
              <div class="font-weight-medium mt-3">Compaign start date</div>
              <div class="opasity--text">{{ data.send_after ? new Date(data.send_after).toLocaleString() : 'Now' }}</div>
              <div class="font-weight-medium mt-3">Link Tracking</div>
              <div class="track white--text px-4" :class="data.track_link_opening ? 'success' : 'error'">
                {{ data.track_link_opening ? 'On' : 'Off' }}
              </div>
            </v-col>
          </v-row>
          <div class="mt-5 text-h6">Message Preview:</div>
          <div class="f18 opasity--text">{{ data.message_text }}</div>
        </div>
        <v-divider class="my-10"></v-divider>
        <div class="text-sm-right text-center">
          <v-btn @click="reject" outlined elevation="0" width="180" height="48" color="error">Reject</v-btn>
          <v-btn @click="allow" class="ml-4" elevation="0" width="180" height="48" color="success">Allow</v-btn>
        </div>
      </v-card>
      <v-dialog max-width="540" v-model="modal">
        <v-card class="px-10 py-5">
          <div class="text-h5">Turget phone numbers</div>
          <div class="opasity--text f14">Treat your recovery codes with the same level of attention as you would your password!</div>
          <div class="overflow-y-auto mt-4 py-3" :style="`height: 200px`">
            <v-row style="width: 450px">
              <v-col class="pa-0 py-1" v-for="i in data.phones" :key="i" cols="6">
                <div class="font-weight-medium text-center">{{ i }}</div>
              </v-col>
            </v-row>
          </div>
          <v-divider class="my-5"></v-divider>
          <div class="text-center">
            <v-btn @click="modal = false" width="120" height="48" color="white">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      modal: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getColorClass(block) {
      if (block == 'submitted') {
        return 'cyan';
      } else if (block == 'started') {
        return 'success';
      } else if (block == 'moderation') {
        return 'warning';
      } else if (block == 'finished') {
        return 'input';
      } else if (block == 'balance_low') {
        return 'gray';
      } else if (block == 'blocked') {
        return 'error';
      } else if (block == 'scheduled') {
        return 'cyan darken-2';
      }
    },
    async getData() {
      await this.$store.dispatch('getCampaign').finally(() => {
        this.load = false;
      });
    },
    async allow() {
      await this.$store.dispatch('allowCampaign').then(() => {
        this.$router.push('/campaigns');
      });
    },
    async reject() {
      await this.$store.dispatch('rejectCampaign').then(() => {
        this.$router.push('/campaigns');
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.campaign;
    },
  },
  destroyed() {
    this.$store.dispatch('setCampaign', { group: {}, send_option: {}, status: {}, targets_type: {} });
  },
};
</script>

<style lang="scss">
.campaign {
  .status {
    line-height: 34px;
    padding: 0 20px;
    width: max-content;
    border-radius: 24px;
  }
  .track {
    line-height: 26px;
    border-radius: 24px;
    width: max-content;
  }
}
</style>
